import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import NewsletterSignup from "../components/static-sections/NewsletterSignupSection"
import CompareSection from "../components/static-sections/CompareSection"
import ApartmentsSeries1Section from "../components/static-sections/ApartmentsSeries1Section"
import ApartmentHomeSection from "../components/static-sections/ApartmentHomeSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import TwoBoxSection from "../components/dynamic-sections/TwoBoxSection"

/** svg */
import ApartmentIcon from "../../assets/apartment-icon.svg"
import BadgeCheckIcon from "../../assets/badge-check-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ConstructionPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/projects/",
          name: "Projects | Norhart",
          image: `${config.siteMetadata.siteUrl}/projects/projects-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Construction Projects | Norhart"
      description="Norhart is building, leasing, and renting apartments in Forest Lake, Blaine, and Oakdale. Norhart pride itself on creating luxury apartments that focus entirely on residence living experiences."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/projects/norhart-projects-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/projects/norhart-projects-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Norhart Projects"
        subtitle="We Build Apartments Where You Belong"
        imageTitle="Norhart Apartments"
        image="/projects/norhart-projects-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Experience Norhart Apartments"
        tagLine="Our apartments will change your life"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <ApartmentHomeSection colorPalette={colorPalette} />

      <TwoBoxSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={ApartmentIcon}
        iconTitle="Norhart Apartments"
        title="Series 2 Apartments"
        subtitle="Norhart's Series 2 apartments focus deeply on enhancing your living experience with great innovations such as: Smart living, movie rooms, gyms, pools, community involvement, and more!"
        boxImgOne="/apartments/norhart-lexington-lofts-apartments-blaine.webp"
        boxAltOne="Norhart Lexington Loft Apartments in Blaine"
        boxTitleOne="Lexington Loft Apartments "
        boxAddressOne="9001 Griggs Ave "
        boxCityOne="Lexington, MN 55014"
        boxPhoneOne="651-237-3322"
        boxURLOne="/blaine/lexington-lofts/"
        boxImgTwo="/apartments/norhart-encore-apartments-forest-lake.webp"
        boxAltTwo="Norhart Encore Apartments in Forest Lake"
        boxTitleTwo="Encore Apartments "
        boxAddressTwo="1081 4th St. SW "
        boxCityTwo="Forest Lake, MN 55025"
        boxPhoneTwo="651-505-2120"
        boxURLTwo="/forest-lake/encore-apartments/"
        colorPalette={colorPalette}
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Exceptional Living"
        title="Loving Life at Norhart"
        subtitle="Norhart offers more than just the old boring plain apartment life. We create living experiences where your home life and your social life unite together. Just another way Norhart is making your life better."
        imageTitleOne="Norhart Loving Life Image 1"
        imageOne="/apartments/gallery/love-life/norhart-loving-life-1.webp"
        imageTitleTwo="Norhart Loving Life Image 2"
        imageTwo="/apartments/gallery/love-life/norhart-loving-life-2.webp"
        imageTitleThree="Norhart Loving Life Image 3"
        imageThree="/apartments/gallery/love-life/norhart-loving-life-3.webp"
        imageTitleFour="Norhart Loving Life Image 4"
        imageFour="/apartments/gallery/love-life/norhart-loving-life-4.webp"
        imageTitleFive="Norhart Loving Life Image 5"
        imageFive="/apartments/gallery/love-life/norhart-loving-life-5.webp"
        imageTitleSix="Norhart Loving Life Image 6"
        imageSix="/apartments/gallery/love-life/norhart-loving-life-6.webp"
        buttonUrl="/services/"
        colorPalette={colorPalette}
        badge={false}
      />

      <CompareSection />

      <ApartmentsSeries1Section colorPalette={colorPalette} />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Living"
        badgeTitle="Great Floorplans"
        title="Beautiful Living Areas"
        subtitle="Choose from one, two, or three-bedroom floor-plans. We carefully designed each space to be functional, luxurious and delightful. #JustForYou!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={BadgeCheckIcon}
        title="Apartment Nice Little Extras"
        subtitle="We invite you to choose a Norhart apartment as your next home. We have carefully crafted and curated a living experience that will enhance your life in every way possible."
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        asoEffect="fade"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Now Open"
        title="Hello. Lexington Lofts"
        subtitle="We invite you to be a part of our newest apartment community in Blaine, MN. At Norhart our teams have designed and built an exceptional living experience from the top down just for you!"
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 1"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        buttonUrl="https://www.norhart.com/blaine/lexington-lofts/"
        colorPalette={colorPalette}
        badge={true}
      />

      <NewsletterSignup colorPalette={colorPalette} />

      <AwardsSection
        header="Norhart Apartments"
        title="Award Winning Apartments"
        subtitle="We invite you to explore our Forest Lake and Blaine Minnesota smart apartments. Once you experience Norhart's smart living, believe me there is no going back!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ConstructionPage
